<template>
  <v-dialog v-model="surveyOpen" width="60%">
    <v-card>
      <v-card-actions>
        <h3 class="font-weight-bold">{{ $t('Profile Config') }}</h3>
      </v-card-actions>
      <v-divider />
      <v-card-title v-if="isLoadingSurvey" class="justify-center" align="center">
        <v-progress-circular indeterminate color="primary" :size="100"></v-progress-circular>
      </v-card-title>
      <v-card>
        <div id="surveyCreator" />
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="#F15858" class="text-white" @click="saveForm()">
            {{ $t('Save') }}
          </v-btn>
          <v-snackbar v-model="errorTitleValidationSnackbar" color="error" :top="true" style="z-index: 5 !important">
            Please fill the survey title
            <template #action="{ attrs }">
              <v-btn text v-bind="attrs" @click="errorTitleValidationSnackbar = false"> {{ $t('Close') }} </v-btn>
            </template>
          </v-snackbar>
        </v-card-actions>
      </v-card>
    </v-card>
  </v-dialog>
</template>

<script>
import { mdiDotsVertical, mdiLabelVariant } from '@mdi/js'

import { default as clientPocketBase } from '@/helpers/pocketbase'
import contactMixin from '../../mixins/contactMixin'
import firestoreDbMixin from '../../mixins/firestoreDbMixin'

// import custom
import pouchDbMixin from '../../mixins/pouchDbMixin'
import utilsMixin from '../../mixins/utilsMixin'
import confirm from '../component/Confirm.vue'

import 'survey-core/defaultV2.min.css'
import 'survey-creator-core/survey-creator-core.min.css'
import { SurveyCreator } from 'survey-creator-knockout'

const creatorOptions = {
  showEmbeddedSurveyTab: false,
  showTestSurveyTab: false,
  showOptions: false,
  showLogicTab: false,
  showJSONEditorTab: false,
  questionTypes: ['text'],
  pageEditMode: 'single',
  showTitlesInExpressions: false,
  allowEditExpressionsInTextEditor: false,
  showSurveyTitle: true,
  isAutoSave: true,
  showToolbox: false,
  showSidebar: false,
  isNavigationButtonsShowing: false,
}

export default {
  name: 'profile-config',
  components: {
    confirm,
  },
  mixins: [firestoreDbMixin, pouchDbMixin, contactMixin, utilsMixin],
  data() {
    return {
      errorTitleValidationSnackbar: false,
      snackBarTimeOut: 2000,
      surveyOpen: false,
      dialogAlert: false,
      resolve: null,
      reject: null,
      dialogForm: false,
      icons: {
        mdiDotsVertical,
        mdiLabelVariant,
      },
      questionSurvey: [],
      idlist: '',
      surveyCreator: '',
      defaultJson: {},
      isLoadingSurvey: false,
      isLoadingSurvey: false,
      selectedFormId: '',
      tagNames: [],
    }
  },
  computed: {
    user() {
      return this.$store.getters['auth/getUser']
    },
  },
  watch: {
    tab() {
      if (this.tab === 1) {
        this.saveField()
      }
    },
    surveyOpen() {
      if (!this.surveyOpen) {
        this.closeDialog()
      }
    },
    tags() {
      //console.log(this.tags, 'tags nyund')
    },
  },
  beforeDestroy() {},
  created() {},
  async mounted() {},
  methods: {
    async open() {
      const parents = this
      parents.surveyCreator = ''
      parents.customDataTable = []
      parents.surveyOpen = true
      parents.isLoadingSurvey = true

      let foundForm = await clientPocketBase
        .collection('crm_contact_form')
        .getOne(this.$Md5Hash(`Profile-Config - ${this.user.sub_id}`))
        .then(
          res => (this.selectedFormId = res.id),
          setTimeout(() => {
            parents
              .loadDataColumn(this.$Md5Hash(`Profile-Config - ${this.user.sub_id}`))
              .then(() => (parents.isLoadingSurvey = false))
          }),
        )
        .catch(
          setTimeout(() => {
            parents.loadDataColumn().then(() => (parents.isLoadingSurvey = false))
          }),
        )
    },
    async closeDialog() {
      this.surveyOpen = false
    },
    remove(id) {},
    async loadDataColumn(formId) {
      this.surveyCreator = new SurveyCreator(creatorOptions)

      if (formId) {
        let foundData = await clientPocketBase.collection('crm_contact_form').getOne(formId)
        let parsedText = JSON.parse(this.surveyCreator.text)
        parsedText.elements = foundData.survey_json
        parsedText.title = foundData.name
        this.tags = foundData.tags
        this.customDataTable = foundData.survey_json
        this.surveyCreator.text = JSON.stringify(parsedText)
        this.surveyCreator.formId = formId
      } else {
        this.surveyCreator.text = JSON.stringify(this.defaultJson)
        this.surveyCreator.formId = undefined
      }
      this.surveyCreator.toolbarItems.splice(2, 4)
      this.surveyCreator.render('surveyCreator')

      setTimeout(() => {
        document.getElementsByClassName('svc-top-bar')[0].style.display = 'none'
        document.getElementsByClassName('svc-creator__banner')[0].style.display = 'none'
      }, 4000)
    },
    async saveForm() {
      const madeSurvey = JSON.parse(this.surveyCreator.text)
      const nanoId = this.$nanoid()

      if (this.surveyCreator.formId) {
        try {
          const editForm = await clientPocketBase.collection('crm_contact_form').update(this.surveyCreator.formId, {
            name: madeSurvey.title,
            survey_json: madeSurvey.elements,
            subId: this.user.sub_id,
            form_id: nanoId,
            is_singleton: true,
          })
        } catch (err) {
          console.log(err, 'ini error 123456')
        }
      } else {
        try {
          let createdData = await clientPocketBase.collection('crm_contact_form').create({
            id: this.$Md5Hash(`Profile-Config - ${this.user.sub_id}`),
            name: madeSurvey.title,
            survey_json: madeSurvey.elements,
            subId: this.user.sub_id,
            form_id: nanoId,
            is_singleton: true,
          })
        } catch (err) {
          console.log(err, 'ini error 123456')
        }
      }
      this.surveyCreator = ''
      this.surveyOpen = false
    },
  },
}
</script>

<style scoped>
#surveyCreator {
  height: 100vh;
}
.svc-creator .svc-full-container .svc-creator__banner .svc-top-bar {
  display: none !important;
}

.height-dialog {
  max-height: 400px;
  overflow-y: auto;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type='number'] {
  -moz-appearance: textfield; /* Firefox */
}
@media only screen and (max-width: 578px) {
  .sm-display {
    display: none;
  }

  .last-served-image {
    display: none;
  }

  .last-served {
    font-size: 12px;
  }

  .phone-number {
    font-size: 12px;
  }
}

@media only screen and (min-width: 578px) {
  .sm-display-mobile {
    display: none;
  }
}
.bottom-action {
  display: none;
}
@media (max-width: 536px) {
  .hide-button {
    display: none;
  }
  .bottom-action {
    display: inherit;
  }
  .block-condition {
    display: flex;
    flex: 1 0 auto;
    min-width: 100% !important;
  }
}
.padding-hero {
  padding-left: 80px;
  padding-right: 80px;
}
.mobile-tag {
  display: none;
}
/* Mobile */

@media (max-width: 536px) {
  .hero-landing {
    justify-content: center;
    text-align: center;
  }
  .mobile-tag {
    display: block;
  }
  .hide-lottie {
    display: none;
  }
  /* .hero-subtitle {
      width: 200px;
    } */
}
</style>
